<template>
  <Component :is="renderComponent" />
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRegistrationStore } from '@/stores/registration'

import RegisterPersonalTermsOldFlow from './RegisterPersonalTermsOldFlow'
import RegisterPersonalTermsStpFlow from './RegisterPersonalTermsStpFlow'

export default {
  name: 'RegisterPersonalTermsWrapper',
  components: {
    RegisterPersonalTermsOldFlow,
    RegisterPersonalTermsStpFlow,
  },
  setup() {
    const registrationStore = useRegistrationStore()
    const renderComponent = computed(() => {
      return registrationStore.redirectToOldFlow
        ? 'RegisterPersonalTermsOldFlow'
        : 'RegisterPersonalTermsStpFlow'
    })

    return {
      renderComponent,
    }
  },
}
</script>
