<template>
  <AppCard class="card-stp terms-card">
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>{{ stepConfig.mainTitle }}</h3>
      </AppCardHeader>
    </template>

    <!-- mobile only -->
    <h3 :class="loading ? 'mb-12' : null" class="type-h2 pb-4 sm:hidden">
      {{ stepConfig.mainTitle }}
    </h3>

    <div class="terms-loading">
      <AppSpinnerBig :loading="loading" inline />
    </div>

    <form v-if="!loading" @submit.prevent="submit">
      <div class="mb-10">
        <div v-for="item in businessData" :key="item.id">
          <div v-if="item.value && item.value.length > 0" class="flex my-2 justify-between">
            <div class="text-left type-caption font-medium leading-6 tracking-wide display-name">
              {{ item.displayName }}
            </div>
            <div class="text-right text-gray type-caption">{{ item.value }}</div>
          </div>
        </div>
      </div>

      <div v-if="showMarketingOptIn" class="opt-in-div">
        <!-- Marketing opt-in -->
        <div>
          <AppInputCheckbox v-model="marketingOptIn" class="sub-opt-in-div-left" />
          <div class="sub-card-right">
            <p class="type-caption text-gray">
              Receive the latest market news, rate updates, and special offers in your email inbox
            </p>
          </div>
        </div>
      </div>
      <XeAlertBox type="info">
        By confirming, you affirm you're a Director (or equivalent) {{ authorizedStr }} to agree to
        our
        <AppLink class="type-caption" :href="termsUrl" :target="target">Terms & Conditions</AppLink>
        and
        <AppLink class="type-caption" :href="privacyUrl" :target="target">Privacy Policy</AppLink>
        on your businesses behalf.
      </XeAlertBox>
      <AppCardFooter class="flex flex-col justify-center">
        <AppButton type="submit" class="w-full" :loading="submitting"> Create account </AppButton>
      </AppCardFooter>
    </form>
  </AppCard>
</template>

<script>
import { reactive, ref, onBeforeMount, computed } from '@vue/composition-api'
import { useRouter } from '@/composables/useRouter'

import AppButton from '@/components/AppButton/AppButton'
import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppInputCheckbox from '@/components/AppInputCheckbox/AppInputCheckbox'
import AppLink from '@/components/AppLink/AppLink'
import AppSpinnerBig from '@/components/AppSpinnerBig/AppSpinnerBig'
import XeAlertBox from '@/components/XeAlertBox/XeAlertBox'

import { useMediaQuery } from '@/composables/useMediaQuery'
import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'

import { useAnalyticsStore } from '@/stores/analytics'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/app'
import { useRegistrationStore } from '@/stores/registration'

export default {
  name: 'PersonalTermsSTP',
  components: {
    AppButton,
    AppCard,
    AppCardHeader,
    AppCardFooter,
    AppIcon,
    AppInputCheckbox,
    AppLink,
    AppSpinnerBig,
    IconChevronLeft,
    XeAlertBox,
  },
  setup() {
    const router = useRouter()

    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const appStore = useAppStore()
    const registrationStore = useRegistrationStore()

    corporateRegistrationStore.activeStepIdx = 3
    corporateRegistrationStore.setActiveStepPageTitle('Business')

    const loading = ref(true)
    const termsUrl = ref(null)
    const privacyUrl = ref(null)
    const target = ref(null)
    const marketingOptIn = ref(true)
    const showMarketingOptIn = ref(null)
    const marketingPromptCompanies = ref(false)
    const termsVersion = ref(null)
    // Get STP config
    const stepConfig = registrationStore.getRegistrationStepConfigById('submit')
    const companyCountry = companyDetailsStore.companyCountry
    const authorizedStr = computed(() =>
      ['US', 'CA'].includes(companyCountry) ? 'authorized' : 'authorised'
    )
    // Get field data
    const businessData = computed(() => {
      return stepConfig?.businessDetails.map((details) => {
        let value = corporateRegistrationStore.getCorporateFormSavedValue('company', details.id)
        if (details.id === 'incorporationdate' && value?.length > 0) {
          const date = new Date(value)
          value = date.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        }

        if (details.id === 'registrationnumber' && ['AU', 'NF'].includes(companyCountry)) {
          details.displayName = 'ABN/ACN'
        } else if (details.id === 'registrationnumber' && ['NZ', 'CK'].includes(companyCountry)) {
          details.displayName = 'NZBN'
        }
        return {
          ...details,
          value: value,
        }
      })
    })
    const isDoiMissing = computed(() =>
      businessData.value.some(
        (item) => item.id === 'incorporationdate' && (!item.value || item.value.length <= 0)
      )
    )
    // Redirect user to fill missing DOI value
    if (isDoiMissing.value) {
      router.push({
        name: 'RegisterBusinessDetails',
      })
    }
    onBeforeMount(async () => {
      try {
        const result = await corporateRegistrationStore.fetchTermsAndConditions()

        termsUrl.value = result.url
        privacyUrl.value = result.privacyUrl
        termsVersion.value = result.version
        target.value = '_blank'

        // Check if company type is soft/hard opt-in
        marketingPromptCompanies.value = registrationStore.getIsSoleCompanyType
        const hardOptInCountries = ['CA', 'AU', 'NF', 'NZ', 'CK']
        if (marketingPromptCompanies.value || hardOptInCountries.includes(companyCountry)) {
          showMarketingOptIn.value = true
        }

        // Set true if the value is soft opt-in
        if (!showMarketingOptIn.value) {
          marketingOptIn.value = true
        }
        loading.value = false
      } catch (ex) {
        appStore.logException({
          text: 'Exception during getting terms and conditions',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
    })

    const receiveDocuments = ref(false)
    if (companyDetailsStore.companyCountry === 'US') {
      receiveDocuments.value = true
    }

    const submitting = ref(false)

    const onClickSearchAgain = () => {
      // Business search again event
      analyticsStore.track({
        event: 'Business Searched Again',
        traits: {
          directorSelected: true,
          marketingOptIn: marketingOptIn.value,
          referenceSource: 'XEMT Business',
          email: authStore.lastLogin,
        },
      })
      router.replace({ name: 'RegisterBusinessSearch' })
    }

    const submit = async () => {
      submitting.value = true
      try {
        if (receiveDocuments.value) {
          await corporateRegistrationStore.submitFieldData({
            group: 'legal',
            formFields: [
              {
                id: 'consenttoreceivedocuments',
                value: true,
              },
            ],
          })
        }
        // Update consent after press the submit button.
        corporateRegistrationStore.marketingOptIn = marketingOptIn.value
        // Send all stored fields

        await corporateRegistrationStore.submitAllFieldsData()
        // Agree t&c until routing to submit-reg page
        await corporateRegistrationStore.agreeTermsAndConditions(termsVersion.value)
        // Terms and conditions event
        analyticsStore.track({
          event: 'Terms and Conditions Accepted',
          traits: {
            privacyPolicyRegion: termsVersion.value,
            tcRegion: termsVersion.value,
            referenceSource: 'XEMT Business',
            email: authStore.lastLogin,
            directorSelected: true,
            marketingOptIn: marketingOptIn.value,
          },
        })
        // Routing user to business information page
        router.push({ name: 'RegisterSubmit' })
      } catch (ex) {
        // TODO anything todo ?
        appStore.logException({
          text: 'Exception during agreeing to terms and conditions',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
      submitting.value = false
    }

    const goBack = () => {
      router.replace({ name: 'RegisterBusinessSearch' })
    }

    return {
      onClickSearchAgain,
      termsUrl,
      privacyUrl,
      receiveDocuments,
      loading,
      target,
      submitting,
      marketingOptIn,
      showMarketingOptIn,
      marketingPromptCompanies,
      stepConfig,
      termsVersion,
      submit,
      goBack,
      mq: reactive(useMediaQuery()),
      businessData,
      authorizedStr,
    }
  },
}
</script>

<style lang="postcss" scoped>
.terms-card {
  ::v-deep .card-content {
    min-height: 100px;
    padding-bottom: 0px !important;
  }

  .link {
    @apply font-normal;
  }
}

.sub-card-borderless {
  @apply rounded-xl;
  @apply my-6;
  @apply p-0;

  .sub-card-borderless-left {
    @apply pb-14;
  }
  .sub-card-borderless-right {
    @apply mx-4;
  }
  div {
    @apply flex gap-0;
    @apply my-3 ml-4 mr-0;
  }
}

.opt-in-div {
  @apply justify-center;
  @apply mb-10;
  @apply p-0;
  .sub-opt-in-div-left {
    @apply flex items-baseline;
  }
  div {
    @apply flex gap-0;

    .sub-card-right {
      @apply flex-col gap-2.5 ml-2;
    }
  }
}

.sub-card {
  @apply justify-center;
  @apply border-blue-lighter border rounded-2xl;
  @apply shadow-ria-1;
  @apply my-6;
  @apply p-0;

  .sub-card-left {
    @apply flex items-baseline;
    padding-top: 0.6875rem;
  }
  div {
    @apply flex gap-0;
    @apply my-3 mx-4;

    .sub-card-right {
      @apply flex-col gap-2.5 ml-2;
    }
  }
}

.manually-enter-section {
  @apply justify-center;
  @apply text-base;

  .button {
    @apply ml-1;

    ::v-deep &.button--text {
      .button-inner {
        @apply p-0;
      }
    }

    &:focus,
    &:hover {
      @apply bg-transparent;
    }
  }
}

.terms-loading {
  ::v-deep .loading-spinner-container {
    @apply static;
  }
}

.personal-terms-text-color {
  font-size: 11px;
  color: rgba(179, 184, 194, 1);
}

.display-name {
  @apply mr-1;

  @screen sm {
    @apply mr-0;
    min-width: 10rem;
  }
}

.card-stp {
  padding-bottom: calc(3rem + 100%);

  @screen sm {
    @apply pb-0;
  }

  & .card-content {
    @apply px-2;
  }
}
</style>
