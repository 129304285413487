<template>
  <div>
    <AppAlert :theme="`${color}-with-bg`" class="alert-borderless">
      <div class="alert-borderless-left">
        <AppIcon size="20" :color="iconColor">
          <IconInformationFilled v-if="alertType === 'info'" />
          <IconWarningAltFilled v-else-if="alertType === 'warning'" />
        </AppIcon>
      </div>
      <div class="alert-borderless-right">
        <h5 v-if="alertTitle" class="font-semibold text-base text-main-black">{{ alertTitle }}</h5>
        <p class="type-caption text-gray">
          <slot></slot>
        </p>
      </div>
    </AppAlert>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import AppAlert from '@/components/AppAlert/AppAlert'
import AppIcon from '@/components/AppIcon/AppIcon'
import { IconInformationFilled, IconWarningAltFilled } from '@moneytransfer.ui/euronet-icons'

export default {
  name: 'XeAlertBox',
  components: {
    AppAlert,
    AppIcon,
    IconInformationFilled,
    IconWarningAltFilled,
  },
  props: {
    type: {
      type: String,
      default: 'info',
    },
    title: {
      type: String,
      default: null,
    },
  },
  setup($props) {
    const alertTitle = computed(() => $props.title)
    const alertType = computed(() => $props.type)
    const color = computed(() => {
      switch ($props.type) {
        case 'info':
          return 'blue'
        case 'warning':
          return 'yellow'
        default:
          return 'info'
      }
    })
    const iconColor = computed(() => {
      switch ($props.type) {
        case 'info':
          return 'rgb(0 108 224)'
        case 'warning':
          return '#b57600'
        default:
          return 'red'
      }
    })

    return {
      alertTitle,
      alertType,
      color,
      iconColor,
    }
  },
}
</script>

<style lang="postcss" scoped>
.alert-borderless {
  @apply rounded-xl;
  @apply mt-1 mb-9;
  @apply p-0;
  align-items: normal;

  .alert-borderless-left {
    @apply pt-1;
  }
  .alert-borderless-right {
    @apply flex flex-col;
    @apply mx-4;
  }
  div {
    @apply flex gap-0;
    @apply my-3 ml-4 mr-0;
  }
}
</style>
